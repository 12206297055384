.button {
    background-color: #18bc9c;
    color: white;
    font-weight: bold;
    border-color: #18bc9c;
    border-radius: .25em;
    line-height: 1.5;
    padding: .375rem .75rem;
    border: 1px solid transparent;
}

* {
    font-family: 'Poppins'
}